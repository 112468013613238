.app-footer-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    min-height: 50px;
    line-height: 50px;
    height: 50px;
    padding: 0;
    background-color: grey;
}

.app-content-container {
    min-height: calc(100vh - 115px);
    max-width: 800px;
    margin: 10px auto !important;
    padding: 10px !important;
    border: none;
    padding-bottom: 60px !important;
}

.ui.menu {
    margin: 0 !important;
}

.application-navbar {
    padding: 10px 10px !important;
    border-bottom: 1px solid grey !important;
}

@media (prefers-reduced-motion: no-preference) {
    .spin {
        animation: logo-spin-animation infinite 2s linear;
    }
}

@keyframes logo-spin-animation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.copy-right {

    font-size: 16px;
    color: white;
    vertical-align: middle;
    height: 50px;
    line-height: 50px;
    text-align: right;
    margin-right: 20px;

    .copy-right-link {
        padding-left: 5px;
        font-weight: bold;
        text-decoration: none;
        color: inherit;

        &:hover {
            color: #61DAFB !important;
        }
    }
}

.icon-container{
    font-size: 16px;
    color: white;
    vertical-align: middle;
    height: 50px;
    line-height: 50px;
    text-align: left;

    .footer{
        margin-left: 10px;
    }

    .icon-clickable{
        &:hover {
            color: #61DAFB !important;
            cursor: pointer;
        }
    }
}


.aboutme-description{
    font-size: 20px !important;
    padding: 10px;
}

.aboutme-header{
    padding-top: 10px;
    padding-left: 10px;
}

.aboutme-technologies{
    margin: 0;
    padding: 0;
    padding-left: 10px;
    font-size: 16px;
}

.project-container{
    margin-bottom: 25px;
}